import React from 'react';
import Parser from 'html-react-parser';
import NonStretchedImage from './NonStretchedImage';

const ContentWithFluidImages = (content, media) => {
  const loaderPlaceholder =
    'data:image/svg+xml,%3Csvg width=\'45\' height=\'45\' viewBox=\'0 0 45 45\' xmlns=\'http://www.w3.org/2000/svg\' stroke=\'%23fff\'%3E%3Cg fill=\'none\' fill-rule=\'evenodd\' transform=\'translate(1 1)\' stroke-width=\'2\'%3E%3Ccircle cx=\'22\' cy=\'22\' r=\'6\' stroke-opacity=\'0\'%3E%3Canimate attributeName=\'r\' begin=\'1.5s\' dur=\'3s\' values=\'6;22\' calcMode=\'linear\' repeatCount=\'indefinite\' /%3E%3Canimate attributeName=\'stroke-opacity\' begin=\'1.5s\' dur=\'3s\' values=\'1;0\' calcMode=\'linear\' repeatCount=\'indefinite\' /%3E%3Canimate attributeName=\'stroke-width\' begin=\'1.5s\' dur=\'3s\' values=\'2;0\' calcMode=\'linear\' repeatCount=\'indefinite\' /%3E%3C/circle%3E%3Ccircle cx=\'22\' cy=\'22\' r=\'6\' stroke-opacity=\'0\'%3E%3Canimate attributeName=\'r\' begin=\'3s\' dur=\'3s\' values=\'6;22\' calcMode=\'linear\' repeatCount=\'indefinite\' /%3E%3Canimate attributeName=\'stroke-opacity\' begin=\'3s\' dur=\'3s\' values=\'1;0\' calcMode=\'linear\' repeatCount=\'indefinite\' /%3E%3Canimate attributeName=\'stroke-width\' begin=\'3s\' dur=\'3s\' values=\'2;0\' calcMode=\'linear\' repeatCount=\'indefinite\' /%3E%3C/circle%3E%3Ccircle cx=\'22\' cy=\'22\' r=\'8\'%3E%3Canimate attributeName=\'r\' begin=\'0s\' dur=\'1.5s\' values=\'6;1;2;3;4;5;6\' calcMode=\'linear\' repeatCount=\'indefinite\' /%3E%3C/circle%3E%3C/g%3E%3C/svg%3E';
  const PreparedContent = Parser(content, {
    replace: (domNode) => {
      if (domNode.name === 'img') {
        const image = media.filter((m) => m.node.wordpress_id === domNode.attribs.media_id * 1);

        if (image && image.length > 0) {
          const imageFile = image[0].node.localFile;
          const altText = image[0].node.alt_text
            ? image[0].node.alt_text
            : 'GClub Casino';

          if (process.env.WP_CONVERT_CONTENT_IMAGES_TO_GATSBY !== '') {
            if (imageFile && imageFile.childImageSharp !== null) {
              return (
                <NonStretchedImage
                  fluid={imageFile.childImageSharp.fluid}
                  alt={altText}
                />
              );
            }
            return <img alt={altText} src={imageFile.publicURL} />;
          } if (process.env.WP_CONTENT_IMAGES_LAZYLOADING === '1') {
            return (
              <img
                alt={altText}
                data-src={imageFile.publicURL}
                src={loaderPlaceholder}
                className="g-lazy"
              />
            );
          }
          return <img alt={altText} src={imageFile.publicURL} />;
        }
        return domNode.toString();
      }
      return false;
    },
  });

  return PreparedContent;
};

export const showContent = (content, media) => (
  <>
    {process.env.WP_REPLACE_CONTENT_IMAGES === '1' ? (
      ContentWithFluidImages(content, media)
    ) : (
      <div key="content" dangerouslySetInnerHTML={{ __html: content }} />
    )}
  </>
);

export default ContentWithFluidImages;
